import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS, FETCH_DEFAULT_CONFIGS_TOKEN } from "../config";
import { ChoicesJsendSuccessResponse, choicesJsendSuccessResponseDecoder } from "../interfaces";
import {
  CertificateLimitsResponse,
  certificateLimitsResponseDecoder,
  CertificateRequestResponse,
  certificateRequestResponseDecoder,
  PrequalificationCalculationResponse,
  prequalificationCalculationResponseDecoder,
  PrequalificationFields,
  PrequalificationOptionsResponse,
  prequalificationOptionsResponseDecoder,
  ProductRequestResponse,
  productRequestResponseDecoder,
  SavingsDepositLimitsResponse,
  savingsDepositLimitsResponseDecoder,
  SavingsPlanPayload,
  SavingsPlanPoolResponse,
  savingsPlanPoolResponseDecoder,
  SavingsPlanResponse,
  savingsPlanResponseDecoder,
  SavingsRequestResponse,
  savingsRequestResponseDecoder,
  TransfersResponse,
  transfersResponseDecoder,
  UserPrequalificationValuesResponse,
  userPrequalificationValuesResponseDecoder,
} from "./interfaces";

export async function saveCertificateRequest(data: FormData): Promise<CertificateRequestResponse> {
  const response = await enhancedFetch(`${API_URL}/request/certificate/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(false),
    method: "POST",
    body: data,
  });

  return certificateRequestResponseDecoder.verify(await response.json());
}

export async function getSavingsAccountDepositLimits(): Promise<SavingsDepositLimitsResponse> {
  const response = await enhancedFetch(`${API_URL}/request/savings/account-deposit-limits/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return savingsDepositLimitsResponseDecoder.verify(await response.json());
}

export async function getSavingsPlanPool(): Promise<SavingsPlanPoolResponse> {
  const response = await enhancedFetch(`${API_URL}/request/savings/plan-pool/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return savingsPlanPoolResponseDecoder.verify(await response.json());
}

export async function getSavingsTypeChoices(): Promise<ChoicesJsendSuccessResponse> {
  const response = await enhancedFetch(`${API_URL}/request/savings/type-choices/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return choicesJsendSuccessResponseDecoder.verify(await response.json());
}

export async function getSavingsPlanChoices(): Promise<ChoicesJsendSuccessResponse> {
  const response = await enhancedFetch(`${API_URL}/request/savings/plan-choices/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return choicesJsendSuccessResponseDecoder.verify(await response.json());
}

export async function getSavingsPlanPeriodicityChoices(): Promise<ChoicesJsendSuccessResponse> {
  const response = await enhancedFetch(`${API_URL}/request/savings/plan-periodicity-choices/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return choicesJsendSuccessResponseDecoder.verify(await response.json());
}

export async function getEditSavingsDepositLimits(): Promise<SavingsDepositLimitsResponse> {
  const response = await enhancedFetch(`${API_URL}/request/savings/edit-deposit-limits/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return savingsDepositLimitsResponseDecoder.verify(await response.json());
}

export async function getCertificateLimits(): Promise<CertificateLimitsResponse> {
  const response = await enhancedFetch(`${API_URL}/request/certificate/limits/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return certificateLimitsResponseDecoder.verify(await response.json());
}

export async function getLiquidationChoices(): Promise<ChoicesJsendSuccessResponse> {
  const response = await enhancedFetch(`${API_URL}/request/certificate/liquidation-choices/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return choicesJsendSuccessResponseDecoder.verify(await response.json());
}

export async function getPrequalificationOptions(): Promise<PrequalificationOptionsResponse> {
  const response = await enhancedFetch(`${API_URL}/request/loan/prequalification-options/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return prequalificationOptionsResponseDecoder.verify(await response.json());
}

export async function getUserPrequalificationValues(): Promise<UserPrequalificationValuesResponse> {
  const response = await enhancedFetch(`${API_URL}/request/loan/prequalification-values/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return userPrequalificationValuesResponseDecoder.verify(await response.json());
}

export async function calculatePrequalification(
  values: PrequalificationFields
): Promise<PrequalificationCalculationResponse> {
  const response = await enhancedFetch(`${API_URL}/request/loan/calculate-prequalification/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(values),
  });

  return prequalificationCalculationResponseDecoder.verify(await response.json());
}

export async function saveAccountRequest(data: FormData): Promise<SavingsRequestResponse> {
  const response = await enhancedFetch(`${API_URL}/request/savings/account/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(false),
    method: "POST",
    body: data,
  });

  return savingsRequestResponseDecoder.verify(await response.json());
}

export async function savePlanRequest(values: SavingsPlanPayload): Promise<SavingsRequestResponse> {
  const response = await enhancedFetch(`${API_URL}/request/savings/plan/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "POST",
    body: JSON.stringify(values),
  });

  return savingsRequestResponseDecoder.verify(await response.json());
}

export async function calculatePlan(values: SavingsPlanPayload): Promise<SavingsPlanResponse> {
  const response = await enhancedFetch(`${API_URL}/request/savings/calculate-plan/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(values),
  });

  return savingsPlanResponseDecoder.verify(await response.json());
}

export async function getProductRequests(): Promise<ProductRequestResponse> {
  const response = await enhancedFetch(`${API_URL}/request/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return productRequestResponseDecoder.verify(await response.json());
}

export async function getTransfers(): Promise<TransfersResponse> {
  const response = await enhancedFetch(`${API_URL}/request/transfers/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return transfersResponseDecoder.verify(await response.json());
}

export async function getBankChoices(): Promise<ChoicesJsendSuccessResponse> {
  const response = await enhancedFetch(`${API_URL}/request/bank-choices/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return choicesJsendSuccessResponseDecoder.verify(await response.json());
}

export async function getAccountTypeChoices(): Promise<ChoicesJsendSuccessResponse> {
  const response = await enhancedFetch(`${API_URL}/request/account-type-choices/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return choicesJsendSuccessResponseDecoder.verify(await response.json());
}

export async function getTypeOfPaymentChoices(): Promise<ChoicesJsendSuccessResponse> {
  const response = await enhancedFetch(`${API_URL}/request/type-of-payment-choices/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return choicesJsendSuccessResponseDecoder.verify(await response.json());
}
