import { Email, FileDownload, Phone } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { getContact } from "Services/api/profile/profile";
import { Query } from "Shared/Query/Query";
import { SectionTitle } from "Shared/styled";
import ValueElement from "Shared/ValueElement/ValueElement";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

const sample = "https://pdfobject.com/pdf/sample.pdf";
const test = "https://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf";

type Step = "confirm" | "documents" | "signing";

export function RequestSignature() {
  const [step, setStep] = useState<Step>("confirm");
  const [searchParams] = useSearchParams();

  if (!searchParams.has("id") || !searchParams.has("type")) return <Typography>{GENERIC_ERROR_MESSAGE}</Typography>;

  if (step === "confirm") return <ProfileConfirmation onContinue={() => setStep("documents")} />;
  if (step === "documents") return <Documents onContinue={() => setStep("signing")} />;
  if (step === "signing") return <Signing />;
  return <Typography>{GENERIC_ERROR_MESSAGE}</Typography>;
}

interface ProfileConfirmationProps {
  onContinue: () => void;
}

function ProfileConfirmation({ onContinue }: ProfileConfirmationProps) {
  const [confirmation, setConfirmation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getContact.name],
    queryFn: async () => {
      try {
        const response = await getContact();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmation(event.target.checked);
  };

  return (
    <Stack>
      <SectionTitle variant="h1">Confirma tus datos</SectionTitle>
      <Stack spacing={2}>
        <Typography>
          Valida que tus datos están correctos. En tu correo recibirás información necesaria para completar tu solicitud
          y los documentos correspondientes a la misma:
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Phone />
          <Query
            result={result}
            OnLoading={() => <Skeleton variant="rectangular" height={48} width={200} />}
            onError={() => <>Ha ocurrido un error al intentar obtener el teléfono</>}
            onSuccess={({ phone }) => <ValueElement title="Teléfono móvil:" value={phone} />}
          />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Email />
          <Query
            result={result}
            OnLoading={() => <Skeleton variant="rectangular" height={48} width={200} />}
            onError={() => <>Ha ocurrido un error al intentar obtener el correo electrónico</>}
            onSuccess={({ email }) => <ValueElement title="Correo electrónico:" value={email} />}
          />
        </Stack>

        <Typography>Si deseas modificar tus datos, presiona el botón &quot;EDITAR PERFIL&quot;</Typography>

        <Box>
          <FormControlLabel
            control={
              <Checkbox checked={confirmation} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />
            }
            label="Confirmo que ambos datos son correctos."
            sx={{ my: "20px" }}
          />
        </Box>

        <Stack gap="15px" sx={{ flexDirection: { md: "row" } }}>
          <Button variant="outlined" fullWidth component={RouterLink} to="/socio">
            EDITAR PERFIL
          </Button>
          <Query
            result={result}
            OnLoading={() => <Skeleton variant="rectangular" height={37} width={"100%"} />}
            onError={() => <>Ha ocurrido un error</>}
            onSuccess={() => (
              <Button
                variant="contained"
                fullWidth
                onClick={() => confirmation && onContinue()}
                disabled={!confirmation}
              >
                CONTINUAR
              </Button>
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

function Documents({ onContinue }: ProfileConfirmationProps) {
  return (
    <Stack>
      <SectionTitle variant="h1">Proceso de firma</SectionTitle>
      <Stack spacing={3}>
        <Typography>Estamos a un paso de firmar los documentos y completar tu solicitud</Typography>
        <Typography>
          Al hacer click en &quot;iniciar&quot;, podrás ver o descargar los documentos que{" "}
          <strong>debes firmar y aceptar usando un código que recibirás en tu correo</strong>. Los documentos que debés
          revisar son los siguientes:
        </Typography>
        <ul>
          <li>
            <strong>Sample</strong>
          </li>
          <li>
            <strong>Test</strong>
          </li>
        </ul>

        <Typography>
          Luego de firmar, enviaremos el contrato y cualquier otro documento relevante a tu correo.
        </Typography>

        <Button variant="contained" fullWidth onClick={onContinue}>
          INICIAR
        </Button>
      </Stack>
    </Stack>
  );
}

function Signing() {
  const ref = useRef(null);
  const [file, setFile] = useState<string>(sample);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    console.log(ref);
    setFile(event.target.value);
  };

  const label = "Seleccionar Documento";

  return (
    <Stack
      gap="15px 0px"
      sx={{ height: { xs: "calc(100vh - 80px - 24px - 56px);", sm: "calc(100vh - 80px - 24px);" }, width: "100%" }}
    >
      <FormControl fullWidth>
        <InputLabel id="document-select-label">{label}</InputLabel>
        <Select labelId="document-select-label" id="document-select" label={label} value={file} onChange={handleChange}>
          <MenuItem value={sample}>Sample File</MenuItem>
          <MenuItem value={test}>Test File</MenuItem>
        </Select>
      </FormControl>

      <object
        data={file}
        type="application/pdf"
        width="100%"
        height="100%"
        aria-labelledby="PDF document"
        title="Documento"
      >
        <Button variant="outlined" fullWidth href={file} download target="_blank" sx={{ height: "100%" }}>
          <Stack alignItems="center" rowGap={"15px"}>
            <FileDownload sx={{ fontSize: "2.5rem" }} />
            <>DESCARGAR DOCUMENTO</>
          </Stack>
        </Button>
      </object>
      <Button variant="contained" fullWidth onClick={handleClickOpen}>
        FIRMAR
      </Button>
      {open && <SigningDialog open={open} onClose={handleClose} />}
    </Stack>
  );
}

interface SigningDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function SigningDialog({ open, onClose }: SigningDialogProps) {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60);
  const { seconds } = useTimer({ expiryTimestamp });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Firma con código</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", rowGap: "15px" }}>
        <DialogContentText>Introduce el código enviado a t*****t@com</DialogContentText>
        <TextField required margin="dense" id="name" name="email" fullWidth variant="outlined" />
        <DialogContentText>Puede reenviar el código en {seconds} segundos. Le quedan 2 reenvíos.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="contained" fullWidth>
          Firmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
