import { Skeleton, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { useNumericFormat } from "react-number-format";
import { getEditSavingsDepositLimits } from "Services/api/request/request";
import { SavingsDepositLimitsResponse } from "Services/api/request/interfaces";
import { SavingsAccount } from "Services/api/profile/interfaces";
import FormikForm from "Shared/FormikForm/FormikForm";
import { numericConfig } from "Shared/FormikMoney/FormikMoney";
import { FormikSlider } from "Shared/FormikSlider/FormikSlider";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { otpCodeSchema, OtpValidation } from "Shared/OtpValidation/OtpValidation";
import { Query } from "Shared/Query/Query";
import { SavingsAccountSelect } from "Shared/SavingAccountSelect/SavingAccountSelect";
import { SectionTitle } from "Shared/styled";
import { requiredNumberValidation } from "Utils/validations";
import * as Yup from "yup";

export function SavingsEditRequest() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getEditSavingsDepositLimits.name],
    queryFn: async () => {
      try {
        const response = await getEditSavingsDepositLimits();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Stack>
      <SectionTitle variant="h1">Modificación de cuenta de ahorros</SectionTitle>
      <Query
        result={result}
        OnLoading={() => (
          <Stack width={"100%"} spacing={2}>
            <Stack spacing={1} width="100%">
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Stack>
            <Stack spacing={1} width="100%">
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Stack>
            <Stack spacing={1} width="100%">
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Stack>

            <Skeleton variant="rectangular" width={"100%"} height={37} />
          </Stack>
        )}
        onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
        onSuccess={(result) => <Form result={result} />}
      />
    </Stack>
  );
}

function Form({ result }: { result: SavingsDepositLimitsResponse["data"] }) {
  const [step, setStep] = useState<"data" | "code">("data");
  const { minMonthlyDeposit, maxMonthlyDeposit } = result;
  const { enqueueSnackbar } = useSnackbar();
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });
  const [account, setAccount] = useState<SavingsAccount>();

  return (
    <Formik
      initialValues={{ account: "", monthlyDeposit: minMonthlyDeposit, code: "" }}
      onSubmit={async (values, { setSubmitting }) => {
        if (step === "code") {
          try {
            await Promise.resolve(console.log(values));
          } catch (error) {
            enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
          }
        } else setStep("code");

        setSubmitting(false);
      }}
      validationSchema={Yup.object({
        account: requiredNumberValidation,
        monthlyDeposit: Yup.number()
          .required("Requerido")
          .integer("Debe ser un entero")
          .positive("Debe ser un entero positivo")
          .min(minMonthlyDeposit, `No menos de RD$${format(minMonthlyDeposit.toString())}`)
          .max(maxMonthlyDeposit, `No mas de RD$${format(maxMonthlyDeposit.toString())}`),
        code: Yup.string().when({
          is: () => step === "code",
          then: () => otpCodeSchema,
        }),
      })}
    >
      <FormikForm width="100%">
        {step === "data" ? (
          <Fragment>
            <SavingsAccountSelect name="account" label="Seleccionar cuenta" onChange={(value) => setAccount(value)} />
            {account && (
              <Quota
                quota={account.quota}
                minMonthlyDeposit={minMonthlyDeposit}
                maxMonthlyDeposit={maxMonthlyDeposit}
              />
            )}
            <FormikSubmitButton fullWidth variant="contained">
              Siguiente
            </FormikSubmitButton>
          </Fragment>
        ) : (
          <OtpValidation onBack={() => setStep("data")} />
        )}
      </FormikForm>
    </Formik>
  );
}

function Quota({
  quota,
  minMonthlyDeposit,
  maxMonthlyDeposit,
}: {
  quota: SavingsAccount["quota"];
  minMonthlyDeposit: number;
  maxMonthlyDeposit: number;
}) {
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });

  return (
    <Fragment>
      <Stack spacing={1}>
        <label>{"Cuota Actual"}</label>
        <Typography>RD${format(quota)}</Typography>
      </Stack>
      <FormikSlider
        id="monthlyDeposit"
        name="monthlyDeposit"
        label="Cuota deseada"
        aria-label="Cuota deseada"
        valueLabelDisplay="auto"
        min={minMonthlyDeposit}
        max={maxMonthlyDeposit}
        step={200}
        marks
        formatValue={(value) => `RD$${format(value)}`}
      />
    </Fragment>
  );
}
