import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import {
  LoanDataResponse,
  PrequalificationResponse,
  PrequalificationResponseData,
} from "Services/api/register/interfaces";
import {
  confirmLoanData,
  getPrequalificationValues,
  saveLoanData,
  savePrequalification,
} from "Services/api/register/register";
import { getPrequalificationOptions } from "Services/api/request/request";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import FormikCheckbox from "Shared/FormikCheckbox/FormikCheckbox";
import FormikForm from "Shared/FormikForm/FormikForm";
import { numericConfig } from "Shared/FormikMoney/FormikMoney";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import {
  LoanDataForm,
  LoanRequestSteps,
  PrequalificationForm,
  prequalificationSchema,
  PrequalificationSkeleton,
  useLoanDataFormSchema,
  Verification,
} from "Shared/LoanRequestForm/LoanRequestForm";
import { Query } from "Shared/Query/Query";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNumericFormat } from "react-number-format";
import * as Yup from "yup";
import { useRegistrationContext } from "../Registration";

export function Loan() {
  const [{ id }, setRegContext] = useRegistrationContext();
  const [step, setStep] = useState<LoanRequestSteps>("prequalification");
  const [{ affiliationFees, prequalification, savingsWarrantyRate }, setPrequalification] =
    useState<PrequalificationResponseData>({
      prequalification: 0,
      affiliationFees: 0,
      savingsWarrantyRate: 0,
    });
  const [loanData, setLoanData] = useState<LoanDataResponse>({
    amount: 0,
    administrativeCharges: 0,
    amountAfterCharges: 0,
    includeSavingsWarranty: false,
    savingsWarranty: 0,
    includeAffiliationFees: false,
    affiliationFees: 0,
    term: 6,
    interestRate: 0,
    payment: "0",
    paymentDay: 28,
    secondPaymentDay: 0,
    paymentPlan: [],
  });
  const { enqueueSnackbar } = useSnackbar();

  switch (step) {
    case "prequalification":
      return (
        <SplitFormContainer
          title="Datos precalificación"
          sideInfoTop={<AccessInfo />}
          form={
            <PrequalificationStepForm
              onSubmit={(data) => {
                setPrequalification(data);
                setStep("data");
              }}
            />
          }
        />
      );

    case "data":
      return (
        <SplitFormContainer
          title="Solicitud de financiamiento"
          sideInfoTop={<AccessInfo />}
          form={
            <Data
              affiliationFees={affiliationFees}
              prequalification={prequalification}
              savingsWarrantyRate={savingsWarrantyRate}
              onBack={() => setStep("prequalification")}
              onSubmit={(data) => {
                setLoanData(data);
                setStep("verification");
              }}
            />
          }
        />
      );

    case "verification":
      return (
        <SplitFormContainer
          title="Verificación"
          sideInfoTop={<AccessInfo />}
          form={<Verification loanData={loanData} />}
          after={
            <Stack direction="row" width="100%" spacing={2}>
              <Button
                fullWidth
                startIcon={<ChevronLeft />}
                sx={{ justifyContent: "flex-start" }}
                onClick={() => setStep("data")}
              >
                ATRAS
              </Button>
              <Button
                fullWidth
                endIcon={<ChevronRight />}
                sx={{ justifyContent: "flex-end" }}
                onClick={() => {
                  void confirmLoanData(id)
                    .then(({ status, data }) => {
                      if (status === "fail") {
                        enqueueSnackbar("Ha ocurrido un error", {
                          variant: "error",
                        });
                        enqueueSnackbar(data, {
                          variant: "error",
                        });
                        setStep("data");
                        return;
                      }

                      setRegContext(data);
                    })
                    .catch((error) => {
                      console.error(error);
                      enqueueSnackbar("Ha ocurrido un error", {
                        variant: "error",
                      });
                      setStep("data");
                    });
                }}
              >
                SIGUIENTE
              </Button>
            </Stack>
          }
        />
      );
  }
}

function PrequalificationStepForm({ onSubmit }: { onSubmit: (data: PrequalificationResponse["data"]) => void }) {
  const [{ id }] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  const resultOptions = useQuery({
    queryKey: [getPrequalificationOptions.name],
    queryFn: async () => {
      try {
        const { data } = await getPrequalificationOptions();
        return data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const resultValues = useQuery({
    queryKey: [getPrequalificationValues.name],
    queryFn: async () => {
      try {
        const response = await getPrequalificationValues({ id });
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Query
      result={resultOptions}
      OnLoading={() => <PrequalificationSkeleton />}
      onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
      onSuccess={(options) => (
        <Query
          result={resultValues}
          OnLoading={() => <PrequalificationSkeleton />}
          onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
          onSuccess={(values) => (
            <Formik
              initialValues={values}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const { data } = await savePrequalification({ id, ...values });
                  onSubmit(data);
                } catch (error) {
                  enqueueSnackbar("Ha ocurrido un error", {
                    variant: "error",
                  });
                  console.error(error);
                }

                setSubmitting(false);
              }}
              validationSchema={prequalificationSchema()}
            >
              <FormikForm width="100%">
                <PrequalificationForm options={options} />
                <FormikSubmitButton fullWidth variant="contained">
                  Siguiente
                </FormikSubmitButton>
              </FormikForm>
            </Formik>
          )}
        />
      )}
    />
  );
}

interface DataProps {
  affiliationFees: number;
  prequalification: number;
  savingsWarrantyRate: number;
  onBack: () => void;
  onSubmit: (data: LoanDataResponse) => void;
}

function Data({ affiliationFees, prequalification, savingsWarrantyRate, onBack, onSubmit }: DataProps) {
  const [{ id, institutionIsAffiliated }] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  const validationSchema = useLoanDataFormSchema(prequalification, institutionIsAffiliated);

  return (
    <Formik
      initialValues={{
        amount: null,
        type: "regular",
        term: 6,
        paymentDay: 28,
        includeSavingsWarranty: false,
        includeAffiliationFees: false,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { data } = await saveLoanData({ id, ...values });
          onSubmit(data);
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", {
            variant: "error",
          });
          console.error(error);
        }

        setSubmitting(false);
      }}
      validationSchema={validationSchema.concat(Yup.object({ includeAffiliationFees: Yup.boolean() }))}
    >
      <FormikForm width="100%">
        <LoanDataForm
          prequalification={prequalification}
          savingsWarrantyRate={savingsWarrantyRate}
          institutionIsAffiliated={institutionIsAffiliated}
        />
        <Stack spacing={1}>
          <label htmlFor={"includeAffiliationFees"}>Monto de afiliación</label>
          <FormikCheckbox
            id="includeAffiliationFees"
            name="includeAffiliationFees"
            label={`Incluir monto de afiliación en el préstamo (RD$${format(affiliationFees.toString())})`}
          />
        </Stack>
        <Stack direction="row" width="100%" spacing={2}>
          <Button fullWidth startIcon={<ChevronLeft />} sx={{ justifyContent: "flex-start" }} onClick={onBack}>
            ATRAS
          </Button>
          <FormikSubmitButton fullWidth endIcon={<ChevronRight />} sx={{ justifyContent: "flex-end" }}>
            SIGUIENTE
          </FormikSubmitButton>
        </Stack>
      </FormikForm>
    </Formik>
  );
}
