import { MenuItem, Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useField } from "formik";
import { useSnackbar } from "notistack";
import { SavingsAccount } from "Services/api/profile/interfaces";
import { getSavingAccounts } from "Services/api/profile/profile";
import FormikSelect, { FormikSelectProps } from "Shared/FormikSelect/FormikSelect";
import { Query } from "Shared/Query/Query";

interface SavingsAccountSelectProps {
  name: string;
  label: string;
  onChange?: (account: SavingsAccount | undefined) => void;
}

export function SavingsAccountSelect({ name, label, onChange = () => undefined }: SavingsAccountSelectProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [, , { setValue }] = useField<number>(name);

  const result = useQuery({
    queryKey: [getSavingAccounts.name],
    queryFn: async () => {
      try {
        const response = await getSavingAccounts();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error obteniendo cuentas del cliente", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const props: FormikSelectProps<number> = {
    id: name,
    name,
    label,
    SelectDisplayProps: { "aria-label": label },
    required: true,
  };

  return (
    <Query
      result={result}
      OnLoading={() => (
        <Stack spacing={1} width="100%">
          <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
          <Skeleton variant="rectangular" width={"100%"} height={56} />
        </Stack>
      )}
      onError={() => <FormikSelect {...props} />}
      onSuccess={(accounts) => (
        <FormikSelect
          {...props}
          onChange={(e) => {
            const value = e.target.value as number;
            setValue(value);
            onChange(accounts.find((account) => account.number === value));
          }}
        >
          {accounts.map(({ number, type }) => {
            return (
              <MenuItem key={number} value={number}>
                {number} - {type}
              </MenuItem>
            );
          })}
        </FormikSelect>
      )}
    />
  );
}
