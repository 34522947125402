import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { SourceOfFundsDataResponse } from "Services/api/profile/interfaces";
import { getSourceOfFundsData } from "Services/api/profile/profile";
import { SaveCertificatePayload } from "Services/api/register/interfaces";
import { CertificateLimitsResponse } from "Services/api/request/interfaces";
import { getCertificateLimits, saveCertificateRequest } from "Services/api/request/request";
import {
  CertificateForm,
  CertificateFormSkeleton,
  useCertificateFormSchema,
} from "Shared/CertificateForm/CertificateForm";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { Query } from "Shared/Query/Query";
import { SectionTitle } from "Shared/styled";
import { requiredValidation } from "Utils/validations";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { createSearchParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";

export function CertificateRequest() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getCertificateLimits.name],
    queryFn: async () => {
      try {
        const response = await getCertificateLimits();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const userDataResult = useQuery({
    queryKey: [getSourceOfFundsData.name],
    queryFn: async () => {
      try {
        const response = await getSourceOfFundsData();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Stack>
      <SectionTitle variant="h1">Solicitud de certificado</SectionTitle>
      <Query
        result={result}
        OnLoading={() => <CertificateFormSkeleton />}
        onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
        onSuccess={(optionsResult) => (
          <Query
            result={userDataResult}
            OnLoading={() => <CertificateFormSkeleton />}
            onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
            onSuccess={(userDataResult) => <Form result={optionsResult} userDataResult={userDataResult} />}
          />
        )}
      />
    </Stack>
  );
}

interface FormProps {
  result: CertificateLimitsResponse["data"];
  userDataResult: SourceOfFundsDataResponse["data"];
}

function Form({ result, userDataResult }: FormProps) {
  const navigate = useNavigate();
  const { minTimeLimit, maxTimeLimit } = result;
  const { institutionIsAffiliated, salary } = userDataResult;
  const { enqueueSnackbar } = useSnackbar();
  const [minTerm] = [minTimeLimit, maxTimeLimit];

  const schema = useCertificateFormSchema({ options: result, institutionIsAffiliated, dbSalary: salary });

  const initialValues: SaveCertificatePayload = {
    amount: null,
    salary: null,
    sourceOfFundsDesc: "",
    sourceOfFundsFile: undefined,
    term: minTerm,
    liquidation: "",
    typeofPayment: "",
    savingsAccount: "",
    account: "",
    accountNumber: "",
    accountBank: "",
    accountType: "",
    accountId: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const requestData = new FormData();

          for (const key in values) {
            const el = values[key as keyof SaveCertificatePayload];
            if (el) requestData.append(key, typeof el === "number" ? el.toString() : el);
          }
          const { data } = await saveCertificateRequest(requestData);

          enqueueSnackbar("Solicitud creada exitosamente", { variant: "success" });

          if (data.status === "in_process")
            navigate({
              pathname: "/solicitudes/firma/",
              search: createSearchParams({
                id: data.uniqueId,
                type: data.type,
              }).toString(),
            });
          else navigate("/solicitudes/estados/");

          setSubmitting(false);
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        }
      }}
      validationSchema={schema.concat(
        Yup.object({
          savingsAccount: Yup.string().when("typeofPayment", {
            is: "withdrawable",
            then: () => requiredValidation,
          }),
        })
      )}
    >
      <FormikForm width="100%">
        <CertificateForm options={result} institutionIsAffiliated={institutionIsAffiliated} dbSalary={salary} />
        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );
}
