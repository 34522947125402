import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { LoanDataResponse } from "Services/api/register/interfaces";
import {
  PrequalificationCalculationResponse,
  UserPrequalificationAdditionalValues,
  UserPrequalificationValues,
} from "Services/api/request/interfaces";
import {
  calculatePrequalification,
  getPrequalificationOptions,
  getUserPrequalificationValues,
} from "Services/api/request/request";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import {
  LoanDataForm,
  LoanRequestSteps,
  PrequalificationForm,
  prequalificationSchema,
  PrequalificationSkeleton,
  useLoanDataFormSchema,
  Verification,
} from "Shared/LoanRequestForm/LoanRequestForm";
import { Query } from "Shared/Query/Query";
import { SectionTitle } from "Shared/styled";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import * as Yup from "yup";

export function LoanRequest() {
  const [step, setStep] = useState<LoanRequestSteps>("prequalification");
  const [prequalificationData, setPrequalificationData] = useState<UserPrequalificationAdditionalValues>({
    isFirstCredit: false,
    prequalification: 0,
    savingsWarrantyRate: 0,
    savingsBalance: 0,
    loanBalance: 0,
  });

  const [prequalification, setPrequalification] = useState<number>(0);
  const [loanData, setLoanData] = useState<LoanDataResponse>({
    amount: 0,
    administrativeCharges: 0,
    amountAfterCharges: 0,
    includeSavingsWarranty: false,
    savingsWarranty: 0,
    includeAffiliationFees: false,
    affiliationFees: 0,
    term: 6,
    interestRate: 0,
    payment: "0",
    paymentDay: 28,
    secondPaymentDay: 0,
    paymentPlan: [],
  });

  switch (step) {
    case "prequalification":
      return (
        <Stack>
          <SectionTitle variant="h1">Datos precalificación</SectionTitle>
          <PrequalificationStepForm
            onDataLoad={(data) => {
              setPrequalification(data.prequalification);
              setPrequalificationData(data);
              if (!data.isFirstCredit) setStep("data");
            }}
            onSubmit={(data) => {
              setStep("data");
              setPrequalification(data);
            }}
          />
        </Stack>
      );

    case "data":
      return (
        <Stack>
          <SectionTitle variant="h1">Solicitud de financiamiento</SectionTitle>
          <Data
            prequalificationData={prequalificationData}
            onBack={() => {
              setStep("prequalification");
            }}
            onSubmit={() => {
              setStep("verification");
            }}
          />
        </Stack>
      );

    case "verification":
      return (
        <Stack>
          <SectionTitle variant="h1">Verificación</SectionTitle>
          <Verification loanData={loanData} />
          <Stack direction="row" width="100%" spacing={2}>
            <Button
              fullWidth
              startIcon={<ChevronLeft />}
              sx={{ justifyContent: "flex-start" }}
              onClick={() => setStep("data")}
            >
              ATRAS
            </Button>
            <Button
              fullWidth
              endIcon={<ChevronRight />}
              sx={{ justifyContent: "flex-end" }}
              onClick={() => {
                console.log("Confirmar");
              }}
            >
              SIGUIENTE
            </Button>
          </Stack>
        </Stack>
      );
  }
}

function PrequalificationStepForm({
  onDataLoad,
  onSubmit,
}: {
  onDataLoad: (data: UserPrequalificationValues) => void;
  onSubmit: (data: PrequalificationCalculationResponse["data"]) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const resultOptions = useQuery({
    queryKey: [getPrequalificationOptions.name],
    queryFn: async () => {
      try {
        const { data } = await getPrequalificationOptions();
        return data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const resultValues = useQuery({
    queryKey: [getUserPrequalificationValues.name],
    queryFn: async () => {
      try {
        const { data } = await getUserPrequalificationValues();
        onDataLoad(data);
        return data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  useEffect(() => {
    if (resultValues.isSuccess) onDataLoad(resultValues.data);
  }, [resultValues, onDataLoad]);

  return (
    <Query
      result={resultOptions}
      OnLoading={() => <PrequalificationSkeleton />}
      onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
      onSuccess={(options) => (
        <Query
          result={resultValues}
          OnLoading={() => <PrequalificationSkeleton />}
          onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
          onSuccess={(values) => (
            <Formik
              initialValues={values}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const { data } = await calculatePrequalification(values);
                  await Promise.resolve(console.log(values));
                  onSubmit(data);
                } catch (error) {
                  enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
                }

                setSubmitting(false);
              }}
              validationSchema={prequalificationSchema()}
            >
              <FormikForm width="100%">
                <PrequalificationForm options={options} />
                <FormikSubmitButton fullWidth variant="contained">
                  Siguiente
                </FormikSubmitButton>
              </FormikForm>
            </Formik>
          )}
        />
      )}
    />
  );
}

interface DataProps {
  prequalificationData: UserPrequalificationAdditionalValues;
  onBack: () => void;
  onSubmit: () => void;
}

function Data({ prequalificationData, onBack, onSubmit }: DataProps) {
  const { isFirstCredit, prequalification, savingsWarrantyRate, savingsBalance, loanBalance } = prequalificationData;
  const { enqueueSnackbar } = useSnackbar();
  const institutionIsAffiliated = false;
  const validationSchema = useLoanDataFormSchema(prequalification, institutionIsAffiliated);

  return (
    <Formik
      initialValues={{
        amount: null,
        type: "regular",
        term: 6,
        paymentDay: 28,
        includeSavingsWarranty: false,
        includeAffiliationFees: false,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await Promise.resolve(console.log(values));
          onSubmit();
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
          console.error(error);
        }

        setSubmitting(false);
      }}
      validationSchema={validationSchema.concat(Yup.object({ includeAffiliationFees: Yup.boolean() }))}
    >
      <FormikForm width="100%">
        <LoanDataForm
          prequalification={prequalification}
          savingsWarrantyRate={savingsWarrantyRate}
          savingsBalance={savingsBalance}
          loanBalance={loanBalance}
          institutionIsAffiliated={institutionIsAffiliated}
        />

        {isFirstCredit ? (
          <Stack direction="row" width="100%" spacing={2}>
            <Button fullWidth startIcon={<ChevronLeft />} sx={{ justifyContent: "flex-start" }} onClick={onBack}>
              ATRAS
            </Button>
            <FormikSubmitButton fullWidth endIcon={<ChevronRight />} sx={{ justifyContent: "flex-end" }}>
              SIGUIENTE
            </FormikSubmitButton>
          </Stack>
        ) : (
          <FormikSubmitButton fullWidth variant="contained">
            Siguiente
          </FormikSubmitButton>
        )}
      </FormikForm>
    </Formik>
  );
}
