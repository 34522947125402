import { MenuItem, Stack } from "@mui/material";
import { RegistrationModel } from "Services/api/register/interfaces";
import { FormikImageField } from "Shared/FormikImageField/FormikImageField";
import { FormikMoney, FormikMoneyField } from "Shared/FormikMoney/FormikMoney";
import FormikSelect from "Shared/FormikSelect/FormikSelect";
import { useField } from "formik";
import { Fragment } from "react";
import * as Yup from "yup";

const salaryFieldName = "salary";

export function SourceOfFunds({
  name,
  institutionIsAffiliated,
  dbSalary,
  amountRequired = false,
}: {
  name: string;
  institutionIsAffiliated: boolean;
  dbSalary: FormikMoneyField;
  amountRequired?: boolean;
}) {
  const [{ value: amount }] = useField<FormikMoneyField>(name);
  const [{ value: salary }] = useField<FormikMoneyField>(salaryFieldName);

  const [requiresSalary, requiredSourceOfFunds] = sourceOfFunds(
    institutionIsAffiliated,
    amount,
    dbSalary,
    salary,
    amountRequired
  );

  return (
    <Fragment>
      {requiresSalary && (
        <FormikMoney id={salaryFieldName} name={salaryFieldName} label="Salario mensual" variant="outlined" required />
      )}
      {requiredSourceOfFunds && (
        <Stack spacing={1}>
          <label htmlFor="sourceOfFundsDesc">Procedencia de fondos</label>
          <FormikSelect id="sourceOfFundsDesc" name="sourceOfFundsDesc" placeholder="Descripción" displayEmpty required>
            <MenuItem value="sellHome">Venta de casa</MenuItem>
            <MenuItem value="sellVehicule">Venta de vehiculo</MenuItem>
          </FormikSelect>
          <FormikImageField name="sourceOfFundsFile" />
        </Stack>
      )}
    </Fragment>
  );
}

export function useSourceOfFundsSchema(
  name: string,
  institutionIsAffiliated: RegistrationModel["institutionIsAffiliated"],
  dbSalary: RegistrationModel["salary"],
  amountRequired = false
) {
  return Yup.object({
    salary: Yup.number()
      .nullable()
      .when(name, {
        is: (amount: FormikMoneyField) =>
          sourceOfFunds(institutionIsAffiliated, amount, dbSalary, null, amountRequired)[0],
        then: (schema) => schema.required("Requerido").moreThan(0, "Salario tiene que ser mayor que cero"),
      }),
    sourceOfFundsDesc: Yup.mixed().when([name, salaryFieldName], {
      is: (amount: FormikMoneyField, salary: FormikMoneyField) =>
        sourceOfFunds(institutionIsAffiliated, amount, dbSalary, salary, amountRequired)[1],
      then: (schema) => schema.required("Requerido"),
    }),
    sourceOfFundsFile: Yup.mixed().when([name, salaryFieldName], {
      is: (amount: FormikMoneyField, salary: FormikMoneyField) =>
        sourceOfFunds(institutionIsAffiliated, amount, dbSalary, salary, amountRequired)[1],
      then: (schema) => schema.required("Archivo requerido"),
    }),
  });
}

function sourceOfFunds(
  institutionIsAffiliated: RegistrationModel["institutionIsAffiliated"],
  amount: FormikMoneyField,
  dbSalary: RegistrationModel["salary"],
  salary: RegistrationModel["salary"],
  amountRequired: boolean
) {
  let requiresSalary = false;
  let requiredSourceOfFunds = false;
  const amnt = amount || 0;
  const s = dbSalary || salary;

  if (institutionIsAffiliated) {
    if (!dbSalary && (amountRequired || amnt > 0)) requiresSalary = true;
    if (s && amnt > 10 * s) {
      requiredSourceOfFunds = true;
    }
  } else if (amnt > 500000) requiredSourceOfFunds = true;

  return [requiresSalary, requiredSourceOfFunds];
}
